<template>
  <div
    style="margin-top: 10px;"
  >
    <section class="main">
      <b-tabs
        v-model="tabIndex"
        style="width: 100%"
      >
        <b-tab
          :active="tabIndex === 0"
          :title="$t('NewOrders')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("NewOrders") }}</strong>
          </template>
          <div
            class="flex"
            style="margin-top: 20px"
          >

            <OrdersList
              :create-order="true"
              @sendON="getON"
              @orderIDName="orderID"
            />
            <ArticleListing
              v-if="client != 'DrenushaXharra'"
              :order-id="orderNumber"
              :create-order="true"
              :tab-index="tabIndex"
            />
            <ArticleListingDrenushaXharra
              v-if="client == 'DrenushaXharra'"
              :order-id="orderNumber"
              :create-order="true"
            />

          </div>
        </b-tab>
        <b-tab
          :active="tabIndex === 1"
          :title="$t('ByPickupDate')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ByPickupDate") }}</strong>
          </template>
          <div>
            <OrdersByDeliveryDate :tab-index="tabIndex" />
          </div>
        </b-tab>
        <b-tab
          :active="tabIndex === 2"
          :title="$t('ByFittingDate')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ByFittingDate") }}</strong>
          </template>
          <div>
            <OrdersByFittingDate :tab-index="tabIndex" />

          </div>
        </b-tab>
      </b-tabs>
    </section>


  </div></template>

<script>
import OrdersList from '@/components/orders/OrdersList.vue'
import ArticleListing from '@/components/orders/ArticleListing.vue'
import ArticleListingDrenushaXharra from '@/components/orders/ArticleListingDrenushaXharra.vue'
import OrdersByDeliveryDate from '@/components/orders/OrdersByDeliveryDate.vue'
import OrdersByFittingDate from '@/components/orders/OrdersByFittingDate.vue'
import { mapGetters } from 'vuex';
import { client } from '../domainConfig'

export default {
  name: 'Home',
  components: {
    OrdersList,
    ArticleListing,
    ArticleListingDrenushaXharra,
    OrdersByDeliveryDate,
    OrdersByFittingDate,
  },
  data() {
    return {
      tabIndex: null,
      orderNumber: '',
      productMatObj: {},
      orderIdName: null,
      client: client.clientName,
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  methods: {
    inside(item) {
      this.productMatObj = item
    },
    getON(id) {
      this.orderNumber = id
    },
    orderID(value) {
      this.orderIdName = value
    },
  },
}
</script>

<style scoped lang="scss">

.flex {
  width: 100%;
}

@media screen and (min-width: 1440px) and (max-width: 1520px) {
  .main{
    padding: 80px 15px 15px 90px;
  }
}

@media screen and (max-width: 1440px){
  .main{
    padding: 75px 15px 15px 80px;
  }
}

@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1200px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
    justify-content: center;
  }

  .flex {
      flex-direction: column;
  }
 }

 @media screen and (max-width: 780px) {
  .main {
    .flex {
      flex-direction: column;
      .color-listing__wrapper {
        margin-left: 0;
      }
    }
  }
 }
</style>
